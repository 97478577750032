/* eslint-disable ssr-friendly/no-dom-globals-in-module-scope */
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';

/**
 * This file in the entry point for rendering React components in CWP - client
 * side rendering only. The tags need to be output by FTL files in CWP.
 *
 * It is currently used for Adviser Centre components but could in theory be used
 * to initialise other kinds of components. However, bear in mind that by default only
 * client side rendering works for this scenario, to have server side rendering
 * support in CWP, it needs to be set up there accordingly.
 *
 * The future CMS will have builtin SSR support for React components, and in that instance
 * this file will not be used to initialise the React components - it will be handled
 * by the framework.
 */

/*
Add global scss / fonts imports
*/
import('./foundational/advisor-center/blackrock-foundational.ts');
import { ComponentWrapper } from './ComponentWrapper';
import { toCamelCase } from './_helpers/toCamelCase';
import { COMPONENT_PREFIX } from './_helpers/componentPrefix.ts';

/*
Add app environment variables to global window
 */
declare global {
	interface Window {
		oneBlkDesignSystem: Record<string, string | number>;
	}
}
window.oneBlkDesignSystem = window.oneBlkDesignSystem || {};
window.oneBlkDesignSystem.version = APP_VERSION;

export async function initCwpClientSideComponent(c: HTMLElement) {
	const componentName = capitalizeFirstLetter(
		toCamelCase(c.tagName.toLowerCase().replace(COMPONENT_PREFIX, ''))
	);
	if (COMMAND === 'serve') {
		// await new Promise<void>((resolve) => setTimeout(() => resolve(), 3000));
	}
	const componentTsx = import(
		`./cwp-entrypoints/${componentName}.component.tsx`
	);
	componentTsx.then(
		async (component) => {
			const ComponentInstance: React.FC & { ssr?: boolean } =
				component[componentName + 'Component'];
			const element = (
				<React.StrictMode>
					<ComponentWrapper
						rootElement={c}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						componentInstance={ComponentInstance}
					/>
				</React.StrictMode>
			);
			if (COMMAND === 'serve') {
				const obs = new MutationObserver((ev) => {
					console.warn('Mutation on render:', ev);
				});
				obs.observe(c, { subtree: true, childList: true });
			}
			if (ComponentInstance.ssr) {
				console.debug('SSR component');
				// root.render(element);
				hydrateRoot(c, element);
			} else {
				createRoot(c).render(element);
			}
		},
		() => {
			console.log(`No component tsx for ${componentName}`);
		}
	);
}

document.querySelectorAll<HTMLElement>('*').forEach((c) => {
	if (c.tagName.indexOf(COMPONENT_PREFIX.toUpperCase()) == 0) {
		console.log(c.tagName);
		initCwpClientSideComponent(c);
	}
});

function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
