import { Dispatch, JSXElementConstructor, useState } from 'react';
import { toCamelCase } from './_helpers/toCamelCase';

/**
 * ComponentWrapper
 *
 * Makes it possible to seamlessly use react components in CWP ftl files.
 * The component (and its params) that needs to be rendered is passed from the bootstrapper (index.tsx).
 * The original dom element that the bootstrapper found is also passed, so that attribute changes can be reflected in the component.
 * Attributes are parsed by the wrapper and passed as an object to the component if possible.
 * This is also true for an attribute named "dataInput" or attribute name ends with "DataInput" for backwards compatibility with the current ftl implementation of AC components.
 * */
export function ComponentWrapper(props: {
	rootElement?: HTMLElement;
	componentInstance: JSXElementConstructor<unknown>;
}) {
	const Child = props.componentInstance;
	const [newProps, setNewProps] = useState<
		unknown & { dataInput?: Record<string, string> }
	>();

	if (props.rootElement !== undefined) {
		const rootElement = props.rootElement;
		watchRootElementAttributes(rootElement, setNewProps);
		if (!newProps) {
			updateParams(rootElement, setNewProps);
		}
	}
	const childPropsWithDataInput = {
		...newProps?.dataInput,
		...newProps,
	};
	const { dataInput, ...childProps } = childPropsWithDataInput;
	return <Child {...childProps} {...dataInput} />;
}

function watchRootElementAttributes(
	rootElement: HTMLElement,
	setNewProps: Dispatch<Record<string, string>>
) {
	const config = { attributes: true, childList: false, subtree: false };
	// Callback function to execute when mutations are observed
	const callback: MutationCallback = (mutationList) => {
		for (const mutation of mutationList) {
			if (mutation.type === 'attributes') {
				console.log(
					`The ${mutation.attributeName} attribute was modified.`,
					(mutation.target as HTMLElement).getAttribute(
						mutation.attributeName || ''
					)
				);
				updateParams(rootElement, setNewProps);
			}
		}
	};
	// Create an observer instance linked to the callback function
	const observer = new MutationObserver(callback);
	// Start observing the target node for configured mutations
	observer.observe(rootElement, config);
}

function updateParams(
	c: HTMLElement,
	setNewProps: Dispatch<Record<string, string>>
) {
	const componentParams: Record<string, string> = {};
	if (c.previousElementSibling?.hasAttribute('data-cwp-component-data')) {
		const data = c.previousElementSibling.textContent;
		if (data) {
			Object.assign(componentParams, JSON.parse(decode(data)));
		}
	} else {
		for (let index = 0; index < c.attributes.length; index++) {
			const attribute = c.attributes[index];
			const name = toCamelCase(attribute.name);
			componentParams[name] = attribute.value;
			parseJsonAttribute(componentParams, name);
		}
	}
	console.log('setting', componentParams);

	setNewProps(componentParams);
}

function parseJsonAttribute(props: Record<string, string>, prop: string) {
	if (typeof props[prop] === 'string') {
		try {
			props[prop] = JSON.parse(props[prop]);
		} catch (_err) {
			if (COMMAND === 'serve') {
				console.warn(`${prop} is not a json input.`);
			}
		}
	}
}

function decode(input: string) {
	const txt = document.createElement('textarea');
	txt.innerHTML = input;
	return txt.value;
}
